<template>
  <div>
    <Matrix2
      :egress_profile_id="egress_profile_id"
      :allows_crud="allows_crud"
      :isBusy="isBusy"
      :courses_wrapper_id="courses_wrapper_id"
    ></Matrix2>

    <MeshCustomFab
      class="noprint"
      :egress_profile_id="egress_profile_id"
      :show_matrix2="false"
    ></MeshCustomFab>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";
export default {
  name: "Matrix2View",
  components: {
    MeshCustomFab: () => import("../MeshCustomFab"),
    Matrix2: () => import("./Matrix2"),
  },
  data() {
    return {
      egress_profile_id: Number(this.$route.params.egress_profile_id),
      courses_wrapper_id: this.$route.params.courses_wrapper_id
        ? Number(this.$route.params.courses_wrapper_id)
        : null,
      isBusy: true,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      user_position: names.USER_POSITION,
      careers: names.CAREERS,
      egressProfiles: names.EGRESS_PROFILES,
    }),
    egressProfile() {
      if (!this.egress_profile_id) return null;
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    allows_crud() {
      if (
        this.career &&
        this.egressProfile &&
        !this.egressProfile.is_closed &&
        ((this.user_position &&
          [1, 2].includes(this.user_position.position) &&
          [1, 2].includes(this.user.groups[0])) ||
          this.user.is_superuser ||
          this.$hasObjectPermission(
            "mesh.faculty",
            "change",
            this.career.faculty
          ) ||
          this.$hasObjectPermission(
            "mesh.career",
            "change",
            this.egressProfile.career
          ))
      ) {
        const has_permission = this.$hasPermissions(
          "mesh.change_egressprofile"
        );
        if (has_permission) {
          this.$store.commit(names.MUTATE_VISIBILITY_CRUD, true);
          return has_permission;
        } else {
          return false;
        }
      } else {
        this.$store.commit(names.MUTATE_VISIBILITY_CRUD, false);
        return false;
      }
    },
  },
  methods: {
    // changeCycle(cycle_id){
    //   this.cycle_id=cycle_id
    //   this.$router.push({
    //     name: "Matrix2View",
    //     params: { egress_profile_id: this.egress_profile_id, cycle_id: cycle_id },
    //   });
    // },
  },
  watch: {
    "$route.params.courses_wrapper_id"() {
      if (
        this.$route.params.courses_wrapper_id &&
        this.$route.params.courses_wrapper_id != null
      )
        this.courses_wrapper_id = Number(this.$route.params.courses_wrapper_id);
      else this.courses_wrapper_id = null;
    },
  },
  created() {
    this.$store
      .dispatch(names.FETCH_EGRESS_PROFILES_MATTERS, {
        egress_profile_id: this.egress_profile_id,
      })
      .then(() => {
        this.isBusy = false;
      });
    this.$store.dispatch(names.FETCH_MATTERS, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_COURSES_WRAPPER, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_TIME_ALLOCATIONS);
    this.$store.dispatch(names.FETCH_CURRENT_POSITION, this.user.id);
    this.$store.dispatch(names.FETCH_FORMATION_LINES);
    this.$store.dispatch(names.FETCH_MATTER_CONTRIBUTIONS);
    this.$store.dispatch(names.FETCH_MATTER_TYPES);
    this.$store.dispatch(names.FETCH_STUDY_ENVIRONMENTS);
    this.$store.dispatch(names.FETCH_CYCLES);
    this.$store.dispatch(names.FETCH_COMPETENCE_TYPES);
    this.$store.dispatch(names.FETCH_METHODOLOGICAL_STRATEGIES);
    this.$store.dispatch(names.FETCH_PROCEDURE_EVIDENCES);
    this.$store.dispatch(names.FETCH_SUPPORT_RESOURCE_TYPES);
    this.$store.dispatch(names.FETCH_STUDY_ENVIRONMENTS_TIME_ALLOCATION);
    this.$store.dispatch(names.FETCH_RA_MICROS);
    if (
      this.egress_profile_id != null &&
      this.institution &&
      this.institution.internal_use_id == "ciisa_uss"
    ) {
      this.$store.dispatch(names.FETCH_COMPETENCE_UNITS, {
        matter__egress_profile_matters__egress_profile__id:
          this.egress_profile_id,
      });
    }
    this.$store.dispatch(names.FETCH_STUDY_UNITS_BY_CICLE_AND_PROFILE, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store
      .dispatch(names.FETCH_EGRESS_PROFILE, this.egress_profile_id)
      .then((response) => {
        this.$store.commit(
          names.MUTATE_TITLE_OF_THE_PRINT,
          `${this.$getVisibleNames("manual.matriz_2", false, "Matriz 2")} - ${
            response.name
          }`
        );
        this.$store
          .dispatch(names.FETCH_CAREER, response.career)
          .then((career) => {
            this.$store.dispatch(names.FETCH_CAPACITIES, {
              faculty_id: career.faculty,
            });
          });
      });
    this.$store.dispatch(names.FETCH_PROFILE_CYCLES, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_RA_BASES, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_EGR_PROFILE_RA_BASES, {
      egress_profile_id: this.egress_profile_id,
    });
    // .then((response) => {
    //   response.forEach((element) => {
    //     // this.$store.dispatch(names.FETCH_RA_BASES, element.competence);
    //     this.$store.dispatch(names.FETCH_COMPETENCE, element.competence);
    //   });
    // });
    this.$store.dispatch(names.FETCH_COMPETENCES, this.egress_profile_id);
    this.$store.dispatch(names.FETCH_RA_MACROS, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_TAXONOMIES);
    // this.$store.dispatch(names.FETCH_EGRESS_PROFILE, this.egress_profile_id);
  },
  mounted() {
    this.$store.commit(names.MUTATE_PRINT_ORIENTATION, false);
    this.$store.commit(names.MUTATE_PRINT_ZOOM_SCALE, 1);
  },
  beforeDestroy() {
    this.$store.commit(names.RESET_PRINT_ZOOM_SCALE);
    this.$store.commit(names.RESET_PRINT_ORIENTATION);
    this.$store.commit(names.RESET_TITLE_OF_THE_PRINT);
  },
};
</script>

<style>
</style>
